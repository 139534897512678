<template>
    <div class="peixunContainer">
        <div class="window">
            <img src="../../assets/peixun.png" class="windowImg"/>
            <div class="windowContent">
                <div class="logoLine">
                    <img src="../../assets/logo.png" class="logo" />
                </div>
                <div class="textLine">赛前培训</div>
                <div class="textLine">8月9日16：00分</div>
                <div class="btnLine">
                    <div class="btn" @click="toPeixun">进入培训</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted(){

    },
    methods:{
        toPeixun(){
            this.$confirm('是否确认进入培训?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(res=>{
                let url = this.$router.resolve({
                    path:'/screen',
                    query:{
                        roomId:'peixun'
                    }
                })
                window.open(url.href,'_blank')
            })
        }
    }
}
</script>

<style lang="less">
.peixunContainer{
    width: 90%;
    .info{
        font-size: 2.6vh;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FEFEFE;
        text-align: center;
    }
    .window{
        margin-top: 1vh;
        position: relative;
        .windowImg{
            width: 100%;
            height: 60vh;
            left: 0;
            top:0;
            z-index: -1;
            position: absolute;
        }
        .windowContent{
            width: 100%;
            height: 100%;
            overflow: hidden;
            .logoLine{
                margin-top:8vh;
                text-align: center;
                .logo{
                    width: 20vh;
                }
            }
            .textLine{
                margin-top:2vh;
                font-size: 2.6vh;
                text-align: center;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #072D76;
            }
            .btnLine{
                margin-top:3vh;
                display: flex;
                justify-content: center;
                align-items: center;
                .btn{
                    width: 30%;
                    height: 5.4vh;
                    line-height: 5.4vh;
                    text-align: center;
                    background: #E74646;
                    border-radius: 10px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    font-size: 2vh;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>